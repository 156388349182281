<template>
  <div class="solve">
    <div class="xzdhjjfa">
      <div class="xzdhjjfa_box">
        <p>行政导航解决方案</p>
        <p>创新式行政导航，督促政务人员积极作为，告别懒政怠政</p>
        <div class="btn">在线咨询</div>
      </div>
    </div>
    <div class="zgzz">
      <div class="zgzz_box">
        <p>在工作中，你也许遇到这些问题</p>
        <img src="@/assets/images/solve/xzdhjjfa/zgzz.png" />
        <div class="zgzz_box_item">
          <div class="zgzz_box_item_row">
            <div class="zgzz_box_item_content">
              <p>事项无法跟踪</p>
              <p>无法查看事项办理的具体进度，要私下沟通 催促加快效率</p>
            </div>
            <div class="zgzz_box_item_content">
              <p>缺乏有效监督</p>
              <p>各办理流程环节缺乏监督，容易出现行政不 作为懒作为</p>
            </div>
          </div>
          <div class="zgzz_box_item_row">
            <div class="zgzz_box_item_content">
              <p>责任人无法明确</p>
              <p>系统无法细化到事项负责人，要辗转沟通才 能找到负责人</p>
            </div>
            <div class="zgzz_box_item_content">
              <p>行政效率低下</p>
              <p>缺乏办理完结期限，工作人员办事不紧不慢</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="xzdh">
      <p>行政导航，工作任务GPS定位到个人</p>
      <p>
        实现对领导指令、征求意见、来文转办、通用事务等工作事务的流程应用和行政导航动态跟踪查询。
        工作落实定位到人，并对各单位办理时限进行考核统计。
      </p>
      <img src="@/assets/images/solve/xzdhjjfa/xzdh.png" />
    </div>
    <div class="dhxt">
      <p>高效协作行政导航系统</p>
      <div class="dhxt_box">
        <div
          class="dhxt_box_label"
          :class="{ select: state === index }"
          v-for="(item, index) in dhxt"
          :key="index"
          @mouseover="select(item, index)"
        >
          <img :src="item.img" />
          <p :class="{ selectText: state === index }">{{ item.label }}</p>
        </div>
      </div>
      <div class="dhxt_item">
        <p>{{ stateItem.title }}</p>
        <img :src="stateItem.img" />
      </div>
    </div>
    <div class="cgal">
      <p>成功案例，与你分享价值所在</p>
      <div class="cgal_box">
        <div class="cgal_box_left">
          <img src="@/assets/images/solve/xzdhjjfa/cgal.png" />
        </div>
        <div class="cgal_box_right">
          <img src="@/assets/images/solve/xzdhjjfa/cgal_icon1.png" />
          <img src="@/assets/images/solve/xzdhjjfa/cgal_icon2.png" />
          <p>
            使用行政导航后，佛山高明全区公职人员都受到了“电子眼”的全程监控，形成全程实时监控区内公职人员办理事项或文件的行政管理模式。这一行政管理模式在高明区大规模应用，涉及全区机关单位的每一个领域、每一个人。是行政督查的大胆创新之举，也有效提升了公职人员的办事效率，告别“人浮于事”、“干多干少一个样”的现象。
          </p>
        </div>
      </div>
    </div>
    <solve-footer />
  </div>
</template>

<script>
import SolveFooter from '@/components/SolveFooter'
import $ from 'jquery'
export default {
  data () {
    return {
      state: 0,
      dhxt: [
        {
          state: 0,
          img: require('@/assets/images/solve/xzdhjjfa/xzdh_icon0_ok.png'),
          label: '发起事务'
        },
        {
          state: 1,
          img: require('@/assets/images/solve/xzdhjjfa/xzdh_icon1.png'),
          label: '执行反馈'
        },
        {
          state: 2,
          img: require('@/assets/images/solve/xzdhjjfa/xzdh_icon2.png'),
          label: '考核统计'
        },
        {
          state: 3,
          img: require('@/assets/images/solve/xzdhjjfa/xzdh_icon3.png'),
          label: '挂钩绩效'
        }
      ],
      stateItem:
      {
        img: require('@/assets/images/solve/xzdhjjfa/xzdh_fqsw0.png'),
        title: '主办单位发起事务并对执行情况进行实时跟踪'
      }
    }
  },
  methods: {
    slide () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.zgzz_box_item_content').offset().top
      if (h > targetHeight - 300) {
        $('.zgzz_box_item_content').addClass('animation')
      }
    },
    select (item, index) {
      this.state = index
      this.dhxt.forEach(element => {
        if (item.label === element.label) {
          element.img = require('@/assets/images/solve/xzdhjjfa/xzdh_icon' + +index + '_ok.png')
        } else {
          element.img = require('@/assets/images/solve/xzdhjjfa/xzdh_icon' + +element.state + '.png')
        }
      })
      var title = ['主办单位发起事务并对执行情况进行实时跟踪', '经办单位按期限时间执行反馈意见', '行政导航考核统计，超时三天以上亮红灯，1-3天黄灯，当天到期蓝灯，未超期绿灯', '指令执行情况纳入各镇街、各有关部门的绩效考核中']
      this.stateItem.img = require('@/assets/images/solve/xzdhjjfa/xzdh_fqsw' + +index + '.png')
      this.stateItem.title = title[index]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.slide)
    setTimeout(() => {
      $('.zgzz_box_item_content').addClass('slide')
    }, 100)
  },
  destroyed () {
    window.removeEventListener('scroll', this.slide)
  },
  components: {
    SolveFooter
  }
}
</script>

<style scoped lang="scss">
.solve {
  width: 100%;
  margin: 0 auto;
  .xzdhjjfa {
    width: 100%;
    height: 300px;
    background-size: auto 100%;
    background: url('~@/assets/images/solve/xzdhjjfa/xzdhjjfa.png') center
      center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    .xzdhjjfa_box {
      width: 1366px;
      height: 300px;
      padding: 0 119px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      box-sizing: border-box;
      p:first-child {
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 18px;
      }
      p:nth-child(2) {
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 18px;
      }
      .btn {
        width: 140px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        border: 1px solid #ffffff;
        border-radius: 10px;
        line-height: 40px;
        text-align: center;
        margin-top: 27px;
        color: #fff;
        cursor: pointer;
      }
      .btn:hover {
        background: #ffffff;
        color: #00cc99;
      }
    }
  }
  .zgzz {
    width: 100%;
    height: 700px;
    margin: 0 auto;
    background-color: #f8fcff;
    position: relative;
    .zgzz_box {
      width: 1366px;
      height: 700px;
      margin: 0 auto;
      box-sizing: border-box;
      p {
        height: 35px;
        font-size: 40px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        position: relative;
        z-index: 999;
        padding-top: 80px;
      }
      img {
        width: 1366px;
        height: 700px;
        margin: 0 auto;
        margin-top: -115px;
      }
      .zgzz_box_item {
        width: 1037px;
        position: absolute;
        top: 241px;
        left: calc(50% - 519px);
        z-index: 999;
        .zgzz_box_item_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 71px;
          .zgzz_box_item_content {
            width: 360px;
            height: 140px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(222, 225, 226, 0.5);
            opacity: 0.75;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 28px;
            box-sizing: border-box;
            transition: all 0.5s;
            transform: translateY(100px);
            opacity: 0;
            p:first-child {
              font-size: 22px;
              font-weight: 400;
              color: #333333;
              padding-top: 0;
              text-align: left;
              padding-bottom: 18px;
            }
            p:nth-child(2) {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              padding-top: 0;
              text-align: left;
            }
          }
          .slide {
            transform: translateY(0);
            opacity: 1;
            transition: all 2s;
          }
          .zgzz_box_item_content:hover {
            transform: translateY(-10px);
            transition: all 0.5s;
          }
        }
      }
    }
  }
  .xzdh {
    width: 1366px;
    height: 780px;
    padding: 0 100px;
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p:first-child {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      margin-top: 79px;
      margin-bottom: 23px;
      text-align: center;
    }
    p:nth-child(2) {
      width: 780px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 40px;
      text-align: center;
    }
    img {
      width: 1024px;
      height: 486px;
    }
  }
  .dhxt {
    width: 100%;
    height: 780px;
    background: #f9fafc;
    padding-top: 79px;
    p {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      padding-bottom: 49px;
    }
    .dhxt_box {
      width: 1366px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      .dhxt_box_label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 240px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #e9ebf3;
        margin-right: 54px;
        cursor: pointer;
        position: relative;
        img {
          width: 64px;
          height: 64px;
          padding-bottom: 25px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          padding-bottom: 0;
        }
        .selectText {
          color: #ffffff;
        }
      }
      .select {
        background: #3784ff;
      }
      .select:after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 116px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 0;
        border-color: #3784ff transparent transparent;
      }
      .dhxt_box_label:last-child {
        margin-right: 0;
      }
    }
    .dhxt_item {
      width: 1120px;
      height: 540px;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 5px 15px 0px rgba(222, 225, 226, 0.5);
      margin-top: 32px;
      padding-top: 42px;
      text-align: center;
      p {
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        padding-bottom: 35px;
      }
      img {
        width: 1054px;
        height: 415px;
      }
    }
  }
  .cgal {
    width: 1366px;
    height: 883px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 246px;
    p {
      font-size: 36px;
      font-weight: 400;
      color: #333333;
      padding-bottom: 42px;
    }
    .cgal_box {
      display: flex;
      justify-content: center;
      align-items: center;
      .cgal_box_left {
        margin-right: 24px;
        img {
          width: 484px;
          height: 539px;
        }
      }
      .cgal_box_right {
        width: 560px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        img:nth-child(1) {
          width: 94px;
          height: 95px;
        }
        img:nth-child(2) {
          width: 553px;
          height: 1px;
          padding-top: 36px;
          padding-bottom: 36px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
        }
      }
    }
  }
}
.el-button,
.el-button--default {
  width: 140px;
  height: 40px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 10px;
  background-color: transparent;
}
</style>
